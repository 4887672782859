/* eslint-disable @typescript-eslint/no-unused-vars */
export default {
  edit: {
    viewBox: '0 0 129 129',
    render: h => (
      <g>
        <path d="M122.8,119.8H6.6c-2.5,0-4.3,2.1-4.3,4.6s2,4.6,4.3,4.6h116.3c2.5,0,4.3-2.1,4.3-4.6S125.2,119.8,122.8,119.8z"/>
        <path d="M2.5,85.1l-0.1,23.3c0,1.3,0.4,2.7,1.4,3.6c1,1,2.1,1.4,3.5,1.4l23.2-0.1c1.3,0,2.6-0.4,3.5-1.4l80.1-80.1c1.9-1.9,1.9-5,0-7L91.2,1.4c-1.9-1.9-5-1.9-7,0L68,17.6L3.9,81.7C3,82.6,2.5,83.8,2.5,85.1z M87.8,12l16.2,16.2l-9.1,9.1L78.6,21L87.8,12z M12.4,87.3l59.1-59.1l16.2,16.2l-59.1,59l-16.3,0.1C12.3,103.4,12.4,87.3,12.4,87.3z"/>
      </g>
    ),
  },
  canmove: {
    viewBox: '0 0 14 14',
    render: h => (
      <g transform="translate(-491 -809)">
        <path d="M3.187,10.905.53,8.248,1.591,7.187,3.717,9.314,5.844,7.187,6.9,8.248,4.248,10.905a.751.751,0,0,1-1.061,0ZM5.844,7.187A.75.75,0,1,1,6.9,8.248Zm-5.314,0a.751.751,0,0,1,1.061,0L.53,8.248A.751.751,0,0,1,.53,7.187ZM5.844,4.248,3.717,2.122,1.591,4.248A.75.75,0,1,1,.53,3.187L3.187.53a.751.751,0,0,1,1.061,0l-.531.531L4.248.53,6.9,3.187A.75.75,0,0,1,5.844,4.248Z" transform="translate(494.289 810.289)"/>
      </g>
    ),
  },
  mute: {
    viewBox: '0 0 14 14',
    render: h => (
      <g transform="translate(-27.3 -2)">
        <path d="M4.927,12.344a2.119,2.119,0,0,1-2.042-1.61H.517a.529.529,0,0,1-.491-.35.546.546,0,0,1,.14-.56c.91-.7.91-1.278.91-3.43V5.488l5.748,5.748A2.262,2.262,0,0,1,4.927,12.344Zm4.41-1.61h-.87L1.384,3.651A4.065,4.065,0,0,1,4.127,1.472V.8a.8.8,0,1,1,1.6,0v.683A3.784,3.784,0,0,1,8.707,5.2V6.394c0,2.151,0,2.73.91,3.43a.546.546,0,0,1,.14.56A.393.393,0,0,1,9.337,10.733Z" transform="translate(29.374 2.817)"/>
        <rect width="15.167" height="1.517" rx="0.758" transform="translate(29.575 3.167) rotate(45)"/>
      </g>
    ),
  },
  unmute: {
    viewBox: '0 0 14 14',
    render: h => (
      <g transform="translate(-27.3 -2)">
        <path d="M2.884,10.733H.517a.528.528,0,0,1-.49-.35.545.545,0,0,1,.14-.56c.91-.7.91-1.26.91-3.43V5.2a3.8,3.8,0,0,1,3.05-3.731V.8a.8.8,0,0,1,1.6,0v.683A3.748,3.748,0,0,1,8.707,5.2V6.394c0,2.17,0,2.73.91,3.43a.545.545,0,0,1,.14.56.393.393,0,0,1-.42.35H7.057a2.3,2.3,0,0,1-2.131,1.61A2.111,2.111,0,0,1,2.884,10.733Z" transform="translate(29.374 2.817)"/>
      </g>
    ),
  },
  crown: {
    viewBox: '0 0 10 10',
    render: h => (
      <g transform="translate(-494 -1092)">
        <g transform="translate(494.508 1094.5)">
          <path d="M6.14,1.8,4.942.225a.639.639,0,0,0-.974,0L2.844,1.8a.612.612,0,0,1-.9,0l-.9-.9A.622.622,0,0,0,0,1.423l.6,3.9c0,.15.15.225.3.225H8.088c.15,0,.3-.075.3-.225l.6-3.9A.581.581,0,0,0,8.013.9l-.974.9A.612.612,0,0,1,6.14,1.8Z"/>
        </g>
      </g>
    ),
  },
  arr_mini: {
    viewBox: '0 0 12 12',
    render: h => (
      <g transform="translate(-1261 -95)">
        <g transform="translate(1271.2 104.599) rotate(180)">
          <path d="M0,3.6a.6.6,0,0,0,.047.227.587.587,0,0,0,.125.188l0,.007,3,3a.6.6,0,0,0,.848-.848L2.048,4.2H7.8A.6.6,0,1,0,7.8,3H2.048L4.024,1.024A.6.6,0,1,0,3.175.175l-3,3,0,.006A.6.6,0,0,0,0,3.6H0Z"/>
        </g>
      </g>
    ),
  },
  'phone-chats': {
    viewBox: '5486 364 14 14',
    render: h => (
      <g transform="translate(2092 1193)">
        <path d="M4.376,11.37A10.242,10.242,0,0,1,1.652,8.408l.555-.349-.007,0-.548.345c0-.007-.01-.014-.013-.021H1.467l.943-.544-.943.544h-.03l.947-.546-.947.546h-.03l.951-.549-.951.549H1.236l-.012-.022a10.212,10.212,0,0,1-1.2-3.842A3.7,3.7,0,0,1,.854.844l.459.469-.018-.019L.854.844l0,0L.937.765l.461.481-.007-.008L.937.765.951.752A1.61,1.61,0,0,1,1.082.645L1.138.6l0,0L1.265.506,1.275.5A2.6,2.6,0,0,1,3.621.132h0a1.105,1.105,0,0,1,.62.5h0a1.12,1.12,0,0,1,.146.471v.011l-.633.037-.023,0,.655-.039.028.474h0l.125,1.869h0a1.112,1.112,0,0,1-.477.991l-.437.318A5.39,5.39,0,0,0,5.372,7.8l.459-.232.019-.009a1.114,1.114,0,0,1,1.114.1h0L8.506,8.689h0L8.2,9.143l-.176.263h0l.481-.716.409.274,0,0a1.116,1.116,0,0,1,.335.362h0a1.111,1.111,0,0,1,.127.782h0a2.6,2.6,0,0,1-1.491,1.854l-.013.006-.255-.6.255.6-.142.06-.006,0-.242-.591.242.591-.07.029c-.05.022-.1.041-.154.058l-.017,0L7.308,11.5l.182.626-.1.031-.016,0a2.447,2.447,0,0,1-.628.08A4.325,4.325,0,0,1,4.376,11.37Zm3.033.085-.241-.542ZM5.563,8.521h0l-.027-.056-.007-.013Zm.876-.114.145-.217Zm-.31-.253-.28-.6.275.59ZM5.487,7.894ZM3.5,4.857ZM2.95,4.746l.033-.006.4-.072h0Zm.429-.077.018,0h0l-.018,0Zm.018,0L3.6,4.628,3.4,4.665Zm.287-.749-.275-.379.023,0-.023,0H3.43l-.023,0-.05-.068.05.068L3.3,3.544h0l.109-.007h0l-.11.008.11-.008ZM3.43,3.536,3.908,3.5l-.478.032h0L3.886,3.5Zm.008-1.879.323-.022h0Zm0-.561L3.717.934l-.278.159h0L3.671.96,3.438,1.1ZM1.659,1.025,1.275.5l.38.522ZM3.411.782h0l0-.015Zm0,2.754h0Z" transform="translate(3396.625 -828.235)"/>
      </g>
    ),
  },
  'phone-ring-chats': {
    viewBox: '5508 388 14 14',
    render: h => (
      <g transform="translate(5508 386)">
        <g transform="translate(0.5 2.503)">
          <path d="M9.562,15.634a6.864,6.864,0,0,1-1.225-.175C5.625,14.5,4.05,11.434,3.875,11.084,3.787,11,1.862,7.934,2.387,5.047a3.127,3.127,0,0,1,1.4-2.1A3.289,3.289,0,0,1,6.15,2.684a.942.942,0,0,1,.7.875l.263,2.713a1.405,1.405,0,0,1-.437.962L6.15,7.5a4.609,4.609,0,0,0,.7,1.925h0A7.434,7.434,0,0,0,8.075,11l.525-.263a.93.93,0,0,1,1.05.088L11.837,12.4a1,1,0,0,1,.438,1.05,3.255,3.255,0,0,1-1.4,1.925A4.957,4.957,0,0,1,9.562,15.634ZM8.25,11ZM7.9,11Z" transform="translate(-2.296 -2.575)"/>
        </g>
        <path d="M-7679.6,7.6a.875.875,0,0,1-.6-.9,4.275,4.275,0,0,0-.5-3.6,4.289,4.289,0,0,0-2.9-1.6.641.641,0,0,1-.6-.8.849.849,0,0,1,.8-.7,6.032,6.032,0,0,1,3.9,2.2,5.88,5.88,0,0,1,.8,4.8.729.729,0,0,1-.7.6Zm-2.9-.5a.715.715,0,0,1-.5-.9,1.987,1.987,0,0,0-.2-1.4,1.735,1.735,0,0,0-1.1-.7.773.773,0,0,1-.7-.8.773.773,0,0,1,.8-.7,3.689,3.689,0,0,1,2.2,1.3,2.9,2.9,0,0,1,.4,2.6c0,.4-.3.6-.7.6Z" transform="translate(7692 2.5)"/>
      </g>
    ),
  },
  cross_close: {
    viewBox: '2208 502 14 14',
    render: h => (
      <g transform="translate(1940 -31)">
        <path d="M-1998.293,17.779a1,1,0,0,1,0-1.415l2.121-2.121-2.121-2.121a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l2.121,2.121,2.121-2.121a1,1,0,0,1,1.415,0,1,1,0,0,1,0,1.414l-2.122,2.121,2.122,2.121a1,1,0,0,1,0,1.414,1,1,0,0,1-1.415,0l-2.121-2.121-2.122,2.122a1,1,0,0,1-.707.292A1,1,0,0,1-1998.293,17.779Z" transform="translate(2269.793 525.793)"/>
      </g>
    ),
  },
}
