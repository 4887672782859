import { defaultLogger } from '@/loggers'

import Icons14 from '@/components/UI/icons/Factory/Icons14'
import Icons16 from '@/components/UI/icons/Factory/Icons16'
import Icons18 from '@/components/UI/icons/Factory/Icons18'
import Icons20 from '@/components/UI/icons/Factory/Icons20'
import Icons24 from '@/components/UI/icons/Factory/Icons24'
import Icons48 from '@/components/UI/icons/Factory/Icons48'
import iconsRaw from '@/components/UI/icons/Factory/iconsRaw'

const getSizeStorage = size => {
  switch (size) {
    case 14: return Icons14
    case 16: return Icons16
    case 18: return Icons18
    case 20: return Icons20
    case 24: return Icons24
    case 48: return Icons48
    case 'raw': return iconsRaw
    default: return null
  }
}

const lookupInAnotherStorages = (icon, size) => {
  const iconInstance = Icons14[icon] || Icons16[icon] || Icons20[icon] || Icons24[icon] || Icons48[icon]

  if (iconInstance) {
    defaultLogger.warn(`[IconFactory.lookupInAnotherStorages] Icon "${icon}" (size: ${size}) found in another size storage`)
  }

  return iconInstance
}

export const createRawSVGComponent = ({ icon, width, height, size, sizeStorage, permanentlyActive }) => {
  const storage = sizeStorage ? getSizeStorage(sizeStorage) : iconsRaw
  const iconInstance = storage[icon]

  if (!iconInstance) throw new Error(`[IconFactory.createRawSVGComponent] There is no template for icon: ${icon}`)

  const { render, viewBox, width: defaultWidth, height: defaultHeight } = iconInstance
  if (!viewBox || !render) return new Error(`[IconFactory.createRawSVGComponent] There is no viewBox (or render) for icon: ${icon}`)

  width = size || width || defaultWidth || sizeStorage
  height = size || height || defaultHeight || sizeStorage

  if (!width || !height) {
    const split = viewBox.split(' ')
    width = width || split[2]
    height = width || split[3]
  }

  return {
    functional: true,
    props: ['active'],
    render: function (h, context) {
      const { props, data } = context

      let className = 'o-svg-raw'
      if (permanentlyActive !== undefined) {
        className += ' ' + (permanentlyActive ? 'active' : 'inactive')
      } else if (props.active) {
        className += ' active'
      }

      return <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        width={width}
        height={height}
        class={className}
        {...data}
      >
        {render(h)}
      </svg>
    },
  }
}

let cacheStorage = null

export const clearCache = (...keys) => {
  if (keys.length === 0) {
    cacheStorage = null
  }

  keys.forEach(key => {
    delete cacheStorage[key]
  })
}

const addCache = (key, value) => {
  const cache = cacheStorage || (cacheStorage = {})
  return (cache[key] = value)
}

const getCache = key => cacheStorage && cacheStorage[key]

export const createSVGComponent = ({ icon, size, sizeStorage, width, height, permanentlyActive, baseClass = 'o-svg', cacheKey }) => {
  if (cacheKey) {
    const value = getCache(cacheKey)
    if (value) return value
  }

  const icons = getSizeStorage(sizeStorage || size)
  if (!icons) throw new Error(`[IconFactory.createSVGComponent] There is no icons container for icon: ${icon} (size: ${size})`)

  const iconInstance = icons[icon] || lookupInAnotherStorages(icon, size)
  if (!iconInstance) throw new Error(`[IconFactory.createSVGComponent] There is no template for icon: ${icon} (size: ${size})`)

  if (!width || !height) {
    width = height = size
  }

  const { render, viewBox: iconViewBox, active: activeInstance } = iconInstance

  const component = {
    functional: true,
    props: ['active'],
    render: function (h, context) {
      const { props, data } = context
      const active = props.active || permanentlyActive

      let viewBox = iconViewBox
      let className = ''
      let renderFn = render

      if (active) {
        if (activeInstance) {
          viewBox = activeInstance.viewBox
          renderFn = activeInstance.render
        }
        className = ' active'
      }

      return <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        width={width}
        height={height}
        class={baseClass + className}
        {...data}
      >
        {renderFn(h)}
      </svg>
    },
  }

  return cacheKey ? addCache(cacheKey, component) : component
}
