/* eslint-disable @typescript-eslint/no-unused-vars */
export default {
  mark_message: {
    viewBox: '4874 520 18 18',
    render: h => (
      <g transform="translate(-346 470)">
        <circle cx="9" cy="9" r="9" transform="translate(5220 50)"/>
        <path
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="1.5px"
          d="M.8,3.838,3.275,6.425,8.9.8" transform="translate(5224.263 55.388)"
        />
      </g>
    ),
  },
  add_new: {
    viewBox: '0 0 18 18',
    render: h => (
      <g>
        <g class="g1" transform="translate(-219 -863)">
          <g transform="translate(219 863)">
            <circle class="stroke" cx="9" cy="9" r="8.25"/>
          </g>
          <g transform="translate(31 750)">
            <rect width="2" height="10" rx="1" transform="translate(196 117)"/>
            <rect width="2" height="10" rx="1" transform="translate(202 121) rotate(90)"/>
          </g>
        </g>
        <g class="g2" transform="translate(-257 -863)">
          <path d="M-2533,241a9.01,9.01,0,0,1-9-9,9.01,9.01,0,0,1,9-9,9.01,9.01,0,0,1,9,9A9.01,9.01,0,0,1-2533,241Zm-4-10a1,1,0,0,0-1,1,1,1,0,0,0,1,1h3v3a1,1,0,0,0,1,1,1,1,0,0,0,1-1v-3h3a1,1,0,0,0,1-1,1,1,0,0,0-1-1h-3v-3a1,1,0,0,0-1-1,1,1,0,0,0-1,1v3Z" transform="translate(2799 640)"/>
        </g>
      </g>
    ),
  },
  // <g transform="translate(-219 -863)"><g class="a" transform="translate(219 863)"><circle class="c" cx="9" cy="9" r="9"/><circle class="d" cx="9" cy="9" r="8.25"/></g><g transform="translate(31 750)"><rect class="b" width="2" height="10" rx="1" transform="translate(196 117)"/><rect class="b" width="2" height="10" rx="1" transform="translate(202 121) rotate(90)"/></g></g>
  // 'mark_message_select': {
  //   viewBox: '4901 520 18 18',
  //   render: (h) => (
  //     <g transform="translate(-319 470)">
  //       <circle cx="9" cy="9" r="9" transform="translate(5220 50)"/>
  //       <path
  //         fill="none"
  //         stroke-linecap="round"
  //         stroke-linejoin="round"
  //         stroke-miterlimit="10"
  //         stroke-width="1.5px"
  //         d="M.8,3.838,3.275,6.425,8.9.8"
  //         transform="translate(5224.263 55.388)"
  //       />
  //     </g>
  //   )
  // }
}
