/* eslint-disable @typescript-eslint/no-unused-vars */
export default {
  backarrow: {
    viewBox: '0 0 16 16',
    render: h => (
      <g transform="translate(-5313 33)">
        <path d="M-5452.175-100.475l-4.6-4.6a.784.784,0,0,1,0-1.1l4.6-4.6a.784.784,0,0,1,1.1,0,.786.786,0,0,1,0,1.1l-3.3,3.3h10.174a.789.789,0,0,1,.8.8.79.79,0,0,1-.8.8h-9.974l3.1,3.1a.784.784,0,0,1,0,1.1.864.864,0,0,1-.6.3A.764.764,0,0,1-5452.175-100.475Z" transform="translate(10771 80.999)"/>
      </g>
    ),
  },
  user: {
    viewBox: '0 0 16 16',
    render: h => (
      <g transform="translate(-507 -552)">
        <g transform="translate(506.344 549.3)">
          <path d="M8.649,17.7c-1.111,0-5-.111-6.222-1.333a.577.577,0,0,1-.222-.444c-.222-1.111.222-3.444,3.222-4.333a2.128,2.128,0,0,0,.889-.444,5.082,5.082,0,0,0-.444-.667,3.281,3.281,0,0,1-1-2.667A3.9,3.9,0,0,1,8.649,3.7a3.969,3.969,0,0,1,3.778,4.111,3.97,3.97,0,0,1-1,2.889c-.333.333-.444.556-.444.667s.444.333.778.444c3.111.889,3.556,3.222,3.333,4.333a.577.577,0,0,1-.222.444C13.649,17.7,9.76,17.7,8.649,17.7ZM3.76,15.478a13.515,13.515,0,0,0,4.889.667,13.932,13.932,0,0,0,4.889-.667c0-.556-.333-1.667-2.111-2.111-.222-.111-1.667-.444-2-1.556-.222-.778.111-1.444.889-2.333a2.483,2.483,0,0,0,.444-1.667A2.345,2.345,0,0,0,8.649,5.367,2.345,2.345,0,0,0,6.538,7.811a2.5,2.5,0,0,0,.556,1.667,2.377,2.377,0,0,1,.889,2.333c-.333,1.111-1.778,1.444-2.111,1.556A2.454,2.454,0,0,0,3.76,15.478Z" transform="translate(0 0)"/>
        </g>
      </g>
    ),
  },
  dropdown: {
    viewBox: '0 0 16 16',
    render: h => (
      <g transform="translate(515 532) rotate(180)">
        <g transform="translate(912.03 1002.23) rotate(180)">
          <rect width="7.7" height="1.5" rx="0.75" transform="translate(399.57 480.169) rotate(-45)"/>
          <rect width="7.7" height="1.5" rx="0.75" transform="translate(409.419 481.23) rotate(-135)"/>
        </g>
      </g>
    ),
  },
  reply: {
    viewBox: '0 0 16 16',
    render: h => (
      <g transform="translate(-679 -516)">
        <g transform="translate(652.5 516)">
          <path d="M32.2,5v.3a1.453,1.453,0,0,0,1.4,1.5,6.477,6.477,0,0,1,4.6,1.8,6.276,6.276,0,0,1,1.2,1.8,10.946,10.946,0,0,0-5.3-1.2h-.4a1.538,1.538,0,0,0-1.5,1.5V11L29.3,8l2.9-3m.7-2.5a1.421,1.421,0,0,0-.6.2L27.6,7.4a.785.785,0,0,0,0,1.1l4.7,4.7a1.072,1.072,0,0,0,.6.2.367.367,0,0,0,.3-.1.752.752,0,0,0,.5-.7v-2h.4c1.7,0,4.8.3,6,2.4a.822.822,0,0,0,.6.4h.2a.858.858,0,0,0,.6-.7,7.885,7.885,0,0,0-2.2-5.2,8.388,8.388,0,0,0-5.6-2.2v-2a.752.752,0,0,0-.5-.7.367.367,0,0,0-.3-.1Z"/>
        </g>
      </g>
    ),
  },
  chat: {
    viewBox: '5121 27 16 16',
    render: h => (
      <g transform="translate(1761 921)">
        <g transform="translate(3360.5 -893.5)">
          <path d="M7.5,1.5a6,6,0,0,1,0,12,5.222,5.222,0,0,1-2.1-.4,4.146,4.146,0,0,0-1.6-.3,1.7,1.7,0,0,0-.7.1L1.9,13l.2-1.2a5.832,5.832,0,0,0-.2-2.3,4.477,4.477,0,0,1-.4-2,6.018,6.018,0,0,1,6-6M7.5,0A7.555,7.555,0,0,0,0,7.5a7.512,7.512,0,0,0,.5,2.6,3.887,3.887,0,0,1,.2,1.5L.4,13.3v.2a1.1,1.1,0,0,0,.8,1.2h.3l1.9-.3h.5a2.959,2.959,0,0,1,1,.2,10.871,10.871,0,0,0,2.6.4h0A7.555,7.555,0,0,0,15,7.5,7.555,7.555,0,0,0,7.5,0Z"/>
        </g>
      </g>
    ),
  },
  plus_addteam: {
    viewBox: '0 0 16 16',
    render: h => (
      <path d="M13.876,5.8H8.2V.2A1.2,1.2,0,0,0,5.8.2V5.8H.2A1.2,1.2,0,0,0,.2,8.2H5.8V13.8a1.2,1.2,0,0,0,2.41,0V8.2H13.8A1.235,1.235,0,0,0,15,7,1.167,1.167,0,0,0,13.876,5.8Z" transform="translate(1 1)"/>
    ),
  },
  sett: {
    viewBox: '5251 27 16 16',
    render: h => (
      <g transform="translate(-30 60)">
        <path d="M6.844,13.684a6.913,6.913,0,0,1-.935-.064.753.753,0,0,1-.595-.469l-.5-1.28-1.251.571a.752.752,0,0,1-.754-.076A6.8,6.8,0,0,1,1.39,10.975l.6-.453-.6.452a.748.748,0,0,1-.091-.751l.55-1.262L.561,8.481a.754.754,0,0,1-.48-.587A6.833,6.833,0,0,1,.065,5.908a.75.75,0,0,1,.468-.595l1.28-.5-.571-1.25a.749.749,0,0,1,.077-.753A6.8,6.8,0,0,1,2.708,1.39l.453.6-.451-.6A.748.748,0,0,1,3.461,1.3l-.3.688.3-.688,1.261.549L5.2.561A.748.748,0,0,1,5.791.082,6.8,6.8,0,0,1,7.776.065a.749.749,0,0,1,.595.469l-.7.273.7-.273.5,1.279,1.251-.571a.749.749,0,0,1,.753.077l-.442.606.442-.606a6.794,6.794,0,0,1,1.417,1.39.749.749,0,0,1,.089.752l-.688-.3.688.3-.55,1.261,1.29.481a.749.749,0,0,1,.479.587,6.8,6.8,0,0,1,.017,1.985.753.753,0,0,1-.469.595l-.274-.7.274.7-1.281.5.572,1.251a.751.751,0,0,1-.076.753l-.606-.442.606.442a6.778,6.778,0,0,1-1.391,1.417.748.748,0,0,1-.752.089l-1.261-.55-.481,1.29a.752.752,0,0,1-.588.479l-.115-.741.115.741A6.793,6.793,0,0,1,6.844,13.684Zm-3.6-1.924h0l-.441.606.441-.6ZM7,4.5A2.5,2.5,0,1,0,9.5,7,2.5,2.5,0,0,0,7,4.5Z" transform="translate(5282.5 -32)"/>
      </g>
    ),
  },
  info: {
    viewBox: '5277 27 16 16',
    render: h => (
      <g transform="translate(-46 -146)">
        <path d="M0,7A6.957,6.957,0,0,1,7,0a6.957,6.957,0,0,1,7,7,6.958,6.958,0,0,1-7,7A6.957,6.957,0,0,1,0,7ZM1.5,7A5.548,5.548,0,0,0,7,12.5,5.549,5.549,0,0,0,12.5,7,5.548,5.548,0,0,0,7,1.5,5.548,5.548,0,0,0,1.5,7ZM6.2,9.8v-3c.1-.5.4-.8.8-.8a.789.789,0,0,1,.8.8V9.9a.774.774,0,0,1-.8.7A.789.789,0,0,1,6.2,9.8ZM6,4.1a.945.945,0,0,1,1-1,.945.945,0,0,1,1,1,.945.945,0,0,1-1,1A.945.945,0,0,1,6,4.1Z" transform="translate(5324 174)"/>
      </g>
    ),
  },
  expand: {
    viewBox: '5053 27 16 16',
    render: h => (
      <g transform="translate(1800 1022)">
        <path d="M4.187,8.9.53,5.248,1.591,4.187,4.717,7.314,7.844,4.187,8.9,5.248,5.248,8.9a.751.751,0,0,1-1.061,0Zm0,0,.284-.284h0ZM7.844,4.187A.75.75,0,0,1,8.9,5.248Zm-7.314,0a.751.751,0,0,1,1.061,0L.53,5.248A.751.751,0,0,1,.53,4.187Z" transform="translate(3256.189 -993.467)"/>
      </g>
    ),
  },
  mark: {
    viewBox: '5199 27 16 16',
    render: h => (
      <g fill="none" class="stroke" transform="translate(3 10)">
        <g transform="translate(-23 -32)">
          <g transform="translate(5220 50)">
            <circle cx="7" cy="7" r="6.25"/>
          </g>
          <path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M.8,3.162,2.725,5.175,7.1.8" transform="translate(5223.138 54.013)"/>
        </g>
      </g>
    ),
  },
  'mark-only': {
    viewBox: '5199 27 16 16',
    render: h => (
      <g fill="none" class="stroke" transform="translate(3 10)">
        <g transform="translate(-23 -32)">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M.8,3.162,2.725,5.175,7.1.8" transform="translate(5223.138 54.013)"/>
        </g>
      </g>
    ),
  },
  mark_filled: {
    viewBox: '5303 177 16 16',
    render: h => (
      <g transform="translate(102 16)">
        <g transform="translate(-18 112)">
          <path d="M7,0A7,7,0,1,1,0,7,7,7,0,0,1,7,0Z" transform="translate(5220 50)"/>
          <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5px" d="M.8,3.162,2.725,5.175,7.1.8" transform="translate(5223.138 54.013)"/>
        </g>
      </g>
    ),
  },
  close: {
    viewBox: '5027 27 16 16',
    render: h => (
      <g transform="translate(1751 936)">
        <path d="M8.292,9.351,4.786,5.846,1.281,9.351A.75.75,0,1,1,.22,8.291L3.725,4.785l-3.5-3.5L1.282.22l3.5,3.5,3.5-3.5,1.06,1.061-3.5,3.5L9.352,8.291a.75.75,0,1,1-1.06,1.06Zm0-9.131a.75.75,0,0,1,1.06,1.061ZM.221.22a.751.751,0,0,1,1.061,0L.221,1.281A.751.751,0,0,1,.221.22Z" transform="translate(3279 -906)"/>
      </g>
    ),
  },
  edit: {
    viewBox: '4949 27 16 16',
    render: h => (
      <g transform="translate(1764 829)">
        <path d="M1.945,13.519a.748.748,0,0,1-.2-.7l.849-3.677.73.169h0l-.73-.168a.751.751,0,0,1,.2-.362L9.63,1.945a2.75,2.75,0,0,1,3.889,3.889L6.683,12.67a.749.749,0,0,1-.362.2l-3.677.849a.751.751,0,0,1-.7-.2Zm.53-.53h0l-.73-.169Zm4.208-.318-.531-.53h0Zm-3.207-.682,2.3-.53L10.741,6.49,8.973,4.722,4.006,9.69ZM11.8,5.429l.656-.657A1.25,1.25,0,1,0,10.69,3.005l-.656.656Z" transform="translate(3185.275 -801.139)"/>
      </g>
    ),
  },
  group_closed: {
    viewBox: '4948 490 8.75 10',
    render: h => (
      <path d="M7.813,4.375H7.344V2.969a2.969,2.969,0,0,0-5.937,0V4.375H.938A.938.938,0,0,0,0,5.313v3.75A.938.938,0,0,0,.938,10H7.813a.938.938,0,0,0,.938-.937V5.313A.938.938,0,0,0,7.813,4.375Zm-2.031,0H2.969V2.969a1.406,1.406,0,0,1,2.813,0Z" transform="translate(4948 490)"/>
    ),
  },
  group_opened: {
    viewBox: '4932.752 490 9.552 9.856',
    render: h => (
      <path d="M2.544-.624A.353.353,0,0,1,2.3-.72a.269.269,0,0,1-.088-.24L2.48-2.688H1.12a.358.358,0,0,1-.264-.1.358.358,0,0,1-.1-.264v-.928a.358.358,0,0,1,.1-.264.358.358,0,0,1,.264-.1h1.6l.352-2.4H1.536a.358.358,0,0,1-.264-.1.358.358,0,0,1-.1-.264v-.928a.358.358,0,0,1,.1-.264.358.358,0,0,1,.264-.1H3.328l.256-1.728a.478.478,0,0,1,.128-.24.326.326,0,0,1,.24-.1H4.9a.326.326,0,0,1,.24.1.326.326,0,0,1,.1.24L4.976-8.416H6.96l.256-1.728a.478.478,0,0,1,.128-.24.326.326,0,0,1,.24-.1h.944a.326.326,0,0,1,.24.1.326.326,0,0,1,.1.24L8.608-8.416H9.936a.358.358,0,0,1,.264.1.358.358,0,0,1,.1.264v.928a.358.358,0,0,1-.1.264.358.358,0,0,1-.264.1H8.368L8-4.352H9.52a.358.358,0,0,1,.264.1.358.358,0,0,1,.1.264v.928a.358.358,0,0,1-.1.264.358.358,0,0,1-.264.1H7.76L7.5-.96a.478.478,0,0,1-.128.24.346.346,0,0,1-.256.1H6.176a.353.353,0,0,1-.248-.1A.269.269,0,0,1,5.84-.96l.272-1.728H4.128L3.872-.96a.478.478,0,0,1-.128.24.346.346,0,0,1-.256.1ZM4.368-4.352H6.352l.352-2.4H4.736Z" transform="translate(4932 500.48)"/>
    ),
  },
  bullhorn: {
    viewBox: '0 0 16 16',
    render: h => (
      <path d="M8 4.66667H2.66666C2.31304 4.66667 1.9739 4.80714 1.72385 5.05719C1.4738 5.30724 1.33333 5.64638 1.33333 6V8.66667C1.33333 9.02029 1.4738 9.35943 1.72385 9.60948C1.9739 9.85952 2.31304 10 2.66666 10H3.33333V12.6667C3.33333 12.8435 3.40357 13.013 3.52859 13.1381C3.65361 13.2631 3.82318 13.3333 3.99999 13.3333H5.33333C5.51014 13.3333 5.67971 13.2631 5.80473 13.1381C5.92976 13.013 6 12.8435 6 12.6667V10H8L11.3333 12.6667V2L8 4.66667ZM10 9.73333L8.66666 8.66667H2.66666V6H8.66666L10 4.93333V9.73333ZM14.3333 7.33333C14.3333 8.47333 13.6933 9.50667 12.6667 10V4.66667C13.6867 5.16667 14.3333 6.2 14.3333 7.33333Z"/>
    ),
  },
  bullhorn_new: {
    viewBox: '0 0 10 9',
    render: h => (
      <path d="M5 2.5H1C0.734784 2.5 0.48043 2.60536 0.292893 2.79289C0.105357 2.98043 0 3.23478 0 3.5V5.5C0 5.76522 0.105357 6.01957 0.292893 6.20711C0.48043 6.39464 0.734784 6.5 1 6.5H1.5V8.5C1.5 8.63261 1.55268 8.75979 1.64645 8.85355C1.74021 8.94732 1.86739 9 2 9H3C3.13261 9 3.25979 8.94732 3.35355 8.85355C3.44732 8.75979 3.5 8.63261 3.5 8.5V6.5H5L7.5 8.5V0.5L5 2.5ZM6.5 6.3L5.5 5.5H1V3.5H5.5L6.5 2.7V6.3ZM9.75 4.5C9.75 5.355 9.27 6.13 8.5 6.5V2.5C9.265 2.875 9.75 3.65 9.75 4.5Z"/>
    ),
  },
}
