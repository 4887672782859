/* eslint-disable @typescript-eslint/no-unused-vars */
export default {
  plus_icon: {
    viewBox: '4786 179 12 12',
    width: 12,
    height: 12,
    render: h => (
      <path d="M10.157,4.1H5.9V-.1A.887.887,0,0,0,5-1a.887.887,0,0,0-.9.9V4.1H-.1A.926.926,0,0,0-1,5a.926.926,0,0,0,.9.9H4.1V10.1a.9.9,0,0,0,1.807,0V5.9H10.1A.926.926,0,0,0,11,5,.875.875,0,0,0,10.157,4.1Z" transform="translate(4787 180)"/>
    ),
  },
  top_files: {
    viewBox: '5013 616 21 24',
    width: 21,
    height: 24,
    render: h => (
      <path d="M-2950.2,989a1.793,1.793,0,0,1-1.8-1.8v-5.1a.789.789,0,0,1,.8-.8c3.3,0,6.3-1.7,6.3-5.2V965.8h2.3a1.078,1.078,0,0,1,.8.2l8.5,8.5a.952.952,0,0,1,.2.7c.1.1.1.2.1.5v11.5a1.793,1.793,0,0,1-1.8,1.8Zm8.7-15.2a.649.649,0,0,0,.6.5v.1h5.3l-5.9-6Zm-12.5,2.6v-8.2a.736.736,0,0,1,.8-.8.789.789,0,0,1,.8.8v8.2a1.865,1.865,0,0,0,2,1.9c1.3,0,2.6-1,2.6-2v-9.2c0-.2-.5-.6-1-.6-.4,0-.9.3-.9.6V974a.736.736,0,0,1-.8.8.788.788,0,0,1-.8-.8v-6.9a2.318,2.318,0,0,1,2.4-2.1,2.416,2.416,0,0,1,2.5,2.1v9.2c0,1.9-2.1,3.5-4.1,3.5A3.4,3.4,0,0,1-2954,976.4Z" transform="translate(7967 -349)"/>
    ),
  },
  top_tasks: {
    viewBox: '4921 616 24 24.001',
    width: 24,
    height: 24,
    render: h => (
      <path d="M-591.6-4141a2.4,2.4,0,0,1-2.4-2.389v-2.03a2.557,2.557,0,0,0,1.8.836h20.4a2.557,2.557,0,0,0,1.8-.836v2.03a2.4,2.4,0,0,1-2.4,2.389Zm0-4.777a2.4,2.4,0,0,1-2.4-2.387v-2.03a2.556,2.556,0,0,0,1.8.835h20.4a2.556,2.556,0,0,0,1.8-.835v2.03a2.4,2.4,0,0,1-2.4,2.387Zm0-4.776a2.4,2.4,0,0,1-2.4-2.387v-9.672a2.4,2.4,0,0,1,2.4-2.389h19.2a2.4,2.4,0,0,1,2.4,2.389v9.672a2.4,2.4,0,0,1-2.4,2.387Zm4.559-8a1.151,1.151,0,0,0,0,1.672l2.761,2.746a1.089,1.089,0,0,0,.839.358,1.091,1.091,0,0,0,.84-.358l5.64-5.611a1.151,1.151,0,0,0,0-1.672,1.164,1.164,0,0,0-1.681,0l-4.8,4.777-1.92-1.911a1.187,1.187,0,0,0-.84-.358A1.187,1.187,0,0,0-587.04-4158.552Z" transform="translate(5515 4781)"/>
    ),
  },
  top_contacts: {
    viewBox: '4967 616 24 24',
    width: 24,
    height: 24,
    render: h => (
      <path d="M21.48,0H4.92A2.461,2.461,0,0,0,2.4,2.52V4.8H1.2A1.134,1.134,0,0,0,0,6,1.134,1.134,0,0,0,1.2,7.2H2.4v3.6H1.2A1.134,1.134,0,0,0,0,12a1.134,1.134,0,0,0,1.2,1.2H2.4v3.6H1.2a1.2,1.2,0,0,0,0,2.4H2.4v2.28A2.461,2.461,0,0,0,4.92,24H21.48A2.461,2.461,0,0,0,24,21.48V2.52A2.461,2.461,0,0,0,21.48,0ZM13.2,6a3,3,0,1,1-3,3A3.048,3.048,0,0,1,13.2,6Zm4.2,11.88c-.36.12-8.04.12-8.4,0s-.72-.48-.6-.84a4.9,4.9,0,0,1,4.8-4.08A5,5,0,0,1,18,17.04.7.7,0,0,1,17.4,17.88Z" transform="translate(4967 616)"/>
    ),
  },
  //
  // Gallery
  //
  gall_downl: {
    viewBox: '7316 2047 24 24',
    width: 24,
    height: 24,
    render: h => (
      <g transform="translate(4108 2872)">
        <path d="M17.858,8.233l-8.1-6.941A1.2,1.2,0,0,0,8.541,1.08a1.134,1.134,0,0,0-.688.979V4.5H1.059A1.184,1.184,0,0,0,0,5.692v6.617A1.184,1.184,0,0,0,1.059,13.5H7.852v2.441a1.134,1.134,0,0,0,.688.979A1.2,1.2,0,0,0,8.964,17a1.087,1.087,0,0,0,.741-.291l8.1-6.941A1.06,1.06,0,0,0,18.122,9,1.014,1.014,0,0,0,17.858,8.233ZM9.97,13.427V12.309a.869.869,0,0,0-.953-.926h-6.9V6.618h6.9a.869.869,0,0,0,.953-.926V4.574L15.5,9Z" transform="translate(3229.061 -823.561) rotate(90)"/>
        <path d="M3,0H15a1,1,0,0,1,1,1v.25a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V1A1,1,0,0,1,3,0Z" transform="translate(3211 -804)"/>
      </g>
    ),
  },
  gall_close: {
    viewBox: '7370 2047 24 24',
    width: 24,
    height: 24,
    render: h => (
      <g transform="translate(4094 2956)">
        <path d="M16.68,18.27,9.3,10.89,1.92,18.27A1.125,1.125,0,1,1,.33,16.679L7.71,9.3.331,1.92,1.922.33,9.3,7.708,16.679.33,18.27,1.92,10.891,9.3l7.38,7.379A1.125,1.125,0,0,1,16.68,18.27Zm0-17.94A1.125,1.125,0,1,1,18.27,1.92ZM.331.33a1.123,1.123,0,0,1,1.59,0L.331,1.92A1.123,1.123,0,0,1,.331.33Z" transform="translate(3278.378 -906.622)"/>
      </g>
    ),
  },
  'fit-img': {
    viewBox: '7705 2139 24 24',
    width: 24,
    height: 24,
    render: h => (
      <g transform="translate(2713 2212)">
        <path d="M13.1,17.549a.778.778,0,1,1,0-1.555h1.838l-3.658-3.658a.79.79,0,0,1,0-1.131.782.782,0,0,1,.56-.239.818.818,0,0,1,.572.238l3.516,3.516V13.165a.784.784,0,0,1,.778-.778.863.863,0,0,1,.635.212.763.763,0,0,1,.212.495v3.677a.784.784,0,0,1-.778.778Zm-11.318,0A.784.784,0,0,1,1,16.772V13.1a.778.778,0,1,1,1.555,0v1.838l3.658-3.658a.806.806,0,0,1,1.372.56.815.815,0,0,1-.239.572L3.83,15.923H5.385a.784.784,0,0,1,.778.778.863.863,0,0,1-.212.635.763.763,0,0,1-.495.212Zm4.4-10.2L2.663,3.83V5.385a.784.784,0,0,1-.778.778.863.863,0,0,1-.635-.212.763.763,0,0,1-.212-.495V1.778A.784.784,0,0,1,1.814,1H5.491a.778.778,0,1,1,0,1.555H3.653L7.312,6.214a.806.806,0,0,1-.56,1.372A.815.815,0,0,1,6.179,7.347Zm5.061-.071a.783.783,0,0,1-.24-.56.817.817,0,0,1,.239-.572l3.516-3.516H13.2a.784.784,0,0,1-.778-.778.863.863,0,0,1,.212-.635A.763.763,0,0,1,13.131,1h3.677a.784.784,0,0,1,.778.778V5.455a.778.778,0,0,1-1.555,0V3.617L12.371,7.276a.817.817,0,0,1-.572.239A.782.782,0,0,1,11.24,7.276Z" transform="translate(4994.5 -70)"/>
      </g>
    ),
  },
  'unfit-img': {
    viewBox: '7705 2168 24 24',
    width: 24,
    height: 24,
    render: h => (
      <g transform="translate(2713 2241)">
        <path d="M16.179,17.311l-3.516-3.516v1.554a.784.784,0,0,1-.778.778.863.863,0,0,1-.635-.212.763.763,0,0,1-.212-.495V11.741a.784.784,0,0,1,.778-.778h3.677a.778.778,0,1,1,0,1.555H13.653l3.659,3.658a.806.806,0,0,1-.56,1.372A.815.815,0,0,1,16.179,17.311Zm-14.939,0a.783.783,0,0,1-.24-.56.817.817,0,0,1,.239-.572l3.516-3.516H3.2a.784.784,0,0,1-.778-.779.863.863,0,0,1,.212-.635.763.763,0,0,1,.5-.212H6.809a.784.784,0,0,1,.778.778V15.49a.778.778,0,0,1-1.555,0V13.652L2.372,17.311a.817.817,0,0,1-.572.239A.782.782,0,0,1,1.24,17.311ZM3.1,7.584a.778.778,0,1,1,0-1.555H4.933L1.274,2.371a.79.79,0,0,1,0-1.131A.782.782,0,0,1,1.834,1a.818.818,0,0,1,.572.238L5.923,4.755V3.2A.784.784,0,0,1,6.7,2.423a.863.863,0,0,1,.635.212.763.763,0,0,1,.212.5V6.808a.784.784,0,0,1-.778.778Zm8.682-.069A.784.784,0,0,1,11,6.737V3.06a.778.778,0,0,1,1.555,0V4.9l3.659-3.658a.79.79,0,0,1,1.131,0,.781.781,0,0,1,.24.56.817.817,0,0,1-.239.572L13.83,5.888h1.554a.784.784,0,0,1,.778.779.863.863,0,0,1-.212.635.763.763,0,0,1-.495.212Z" transform="translate(4994.5 -70)"/>
      </g>
    ),
  },
  'zoom-in': {
    viewBox: '7643 2139 24 24',
    width: 24,
    height: 24,
    render: h => (
      <g transform="translate(4746 2905)">
        <path d="M15.819,16.88l-3.573-3.573A7.5,7.5,0,0,1,0,7.5H1.5a6,6,0,1,0,6-6,6,6,0,0,0-6,6H0a7.5,7.5,0,1,1,13.307,4.747l3.573,3.572a.75.75,0,0,1-1.061,1.061Z" transform="translate(2901 -763)"/>
        <g transform="translate(2374 -1446)">
          <rect width="7" height="1.5" rx="0.75" transform="translate(531 690)"/>
          <rect width="7" height="1.5" rx="0.75" transform="translate(533.75 694.25) rotate(-90)"/>
        </g>
      </g>
    ),
  },
  'zoom-out': {
    viewBox: '7675 2139 24 24',
    width: 24,
    height: 24,
    render: h => (
      <g transform="translate(4778 2905)">
        <path d="M15.819,16.88l-3.573-3.573A7.5,7.5,0,0,1,0,7.5H1.5a6,6,0,1,0,6-6,6,6,0,0,0-6,6H0a7.5,7.5,0,1,1,13.307,4.747l3.573,3.572a.75.75,0,0,1-1.061,1.061Z" transform="translate(2901 -763)"/>
        <g transform="translate(2374 -1446)">
          <rect width="7" height="1.5" rx="0.75" transform="translate(531 690)"/>
        </g>
      </g>
    ),
  },
  'rotate-cw': {
    viewBox: '0 0 24 24',
    width: 24,
    height: 24,
    render: h => (
      <path d="M16.7157 3.47633C14.8657 2.45363 12.7334 2.05987 10.6402 2.35438C8.55076 2.64835 6.61319 3.61219 5.11831 5.101L1.75 8.26606V4C1.75 3.58579 1.41421 3.25 1 3.25C0.585786 3.25 0.25 3.58579 0.25 4V10C0.249999 10.0045 0.250039 10.0089 0.250117 10.0134C0.251934 10.1163 0.274811 10.219 0.318969 10.3146C0.337931 10.3556 0.360816 10.3953 0.387642 10.4331C0.42067 10.4797 0.458886 10.5224 0.501448 10.5603C0.550018 10.6036 0.602976 10.6396 0.658828 10.6681C0.761175 10.7205 0.877137 10.75 1 10.75H7C7.41421 10.75 7.75 10.4142 7.75 10C7.75 9.58579 7.41421 9.25 7 9.25H2.89336L6.15358 6.18651C6.1591 6.18134 6.16453 6.17607 6.16988 6.17073C7.43572 4.90703 9.07796 4.08895 10.8492 3.83975C12.6204 3.59055 14.4246 3.92373 15.99 4.7891C17.5554 5.65446 18.7971 7.00512 19.5281 8.63757C20.2591 10.27 20.4398 12.0958 20.0429 13.8399C19.646 15.584 18.6931 17.1518 17.3277 18.3072C15.9622 19.4626 14.2583 20.1429 12.4726 20.2457C10.6869 20.3484 8.9162 19.8681 7.42724 18.877C5.93827 17.8859 4.81174 16.4377 4.21738 14.7507C4.07974 14.3601 3.65146 14.1549 3.26078 14.2926C2.8701 14.4302 2.66498 14.8585 2.80262 15.2492C3.50505 17.2429 4.8364 18.9544 6.59608 20.1257C8.35576 21.297 10.4484 21.8646 12.5588 21.7432C14.6692 21.6217 16.6829 20.8177 18.2966 19.4522C19.9103 18.0868 21.0365 16.2339 21.5055 14.1727C21.9746 12.1116 21.7611 9.95379 20.8971 8.02453C20.0332 6.09527 18.5657 4.49903 16.7157 3.47633Z" />
    ),
  },
  'rotate-ccw': {
    viewBox: '0 0 22 20',
    width: 22,
    height: 20,
    render: h => (
      <path d="M5.28324 1.46704C7.13336 0.444353 9.26575 0.0505981 11.3591 0.345102C13.4487 0.639069 15.3864 1.60289 16.8813 3.0917L20.25 6.25686V2C20.25 1.58579 20.5858 1.25 21 1.25C21.4142 1.25 21.75 1.58579 21.75 2V8C21.75 8.41421 21.4142 8.75 21 8.75H15C14.5858 8.75 14.25 8.41421 14.25 8C14.25 7.58579 14.5858 7.25 15 7.25H19.1164L15.8461 4.17725C15.8406 4.17207 15.8352 4.16681 15.8298 4.16147C14.5639 2.89777 12.9215 2.07968 11.1501 1.83048C9.37879 1.58127 7.57442 1.91446 6.00891 2.77983C4.44341 3.6452 3.20157 4.99586 2.47051 6.62831C1.73945 8.26075 1.55877 10.0865 1.95568 11.8306C2.35259 13.5746 3.3056 15.1425 4.67112 16.2979C6.03665 17.4532 7.74071 18.1336 9.52656 18.2364C11.3124 18.3391 13.0833 17.8588 14.5724 16.8677C16.0614 15.8766 17.188 14.4284 17.7824 12.7414C17.9201 12.3508 18.3484 12.1456 18.7391 12.2833C19.1297 12.4209 19.3348 12.8492 19.1972 13.2399C18.4947 15.2336 17.1633 16.9451 15.4035 18.1164C13.6437 19.2877 11.5509 19.8553 9.44038 19.7339C7.32988 19.6124 5.31603 18.8084 3.70224 17.443C2.08845 16.0775 0.962161 14.2246 0.493079 12.1635C0.0239952 10.1023 0.237536 7.94449 1.10152 6.01523C1.96551 4.08596 3.43313 2.48973 5.28324 1.46704Z" />
    ),
  },
  next: {
    viewBox: '7742 2039 40 40',
    width: 40,
    height: 40,
    render: h => (
      <g transform="translate(8687 5280) rotate(-90)">
        <path d="M14.719,16.948.461,2.693,2.693.463,15.834,13.6,28.974.463l2.23,2.23L16.948,16.948a1.572,1.572,0,0,1-2.23,0ZM28.974.463a1.577,1.577,0,0,1,2.23,2.23ZM.461.463a1.577,1.577,0,0,1,2.232,0L.461,2.693A1.578,1.578,0,0,1,.461.463Z" transform="translate(3205.166 -933.333)"/>
      </g>
    ),
  },
  //
  // Calls
  //
  members: {
    viewBox: '5558 363 16 16',
    width: 16,
    height: 16,
    render: h => (
      <g transform="translate(5558 361)">
        <path d="M8,16.3c-1,0-4.5-.1-5.6-1.2a.52.52,0,0,1-.2-.4c-.2-1,.2-3.1,2.9-3.9a1.915,1.915,0,0,0,.8-.4,4.574,4.574,0,0,0-.4-.6,2.953,2.953,0,0,1-.9-2.4A3.51,3.51,0,0,1,8,3.7a3.572,3.572,0,0,1,3.4,3.7,3.573,3.573,0,0,1-.9,2.6c-.3.3-.4.5-.4.6s.4.3.7.4c2.8.8,3.2,2.9,3,3.9a.52.52,0,0,1-.2.4C12.5,16.3,9,16.3,8,16.3Zm-4.4-2a12.164,12.164,0,0,0,4.4.6,12.538,12.538,0,0,0,4.4-.6c0-.5-.3-1.5-1.9-1.9C10.3,12.3,9,12,8.7,11c-.2-.7.1-1.3.8-2.1a2.235,2.235,0,0,0,.4-1.5A2.111,2.111,0,0,0,8,5.2,2.111,2.111,0,0,0,6.1,7.4a2.251,2.251,0,0,0,.5,1.5A2.14,2.14,0,0,1,7.4,11c-.3,1-1.6,1.3-1.9,1.4A2.208,2.208,0,0,0,3.6,14.3Z"/>
      </g>
    ),
  },
  minimize: {
    viewBox: '5666 361 20 20',
    width: 20,
    height: 20,
    render: h => (
      <g transform="translate(428 408)">
        <g transform="translate(-0.889 -0.801)">
          <path d="M5.216,11.155a.778.778,0,1,1,0-1.556H8.467L.566,1.7a.788.788,0,0,1,0-1.131A.788.788,0,0,1,1.7.566L9.458,8.327V5.356a.784.784,0,0,1,.778-.778.865.865,0,0,1,.636.212.765.765,0,0,1,.212.5v5.091a.785.785,0,0,1-.778.778Z" transform="translate(5242.562 -43.525)"/>
          <rect width="11" height="1.5" rx="0.75" transform="translate(5243 -30)"/>
        </g>
      </g>
    ),
  },
  maximize: {
    viewBox: '5688 363 16 16',
    width: 16,
    height: 16,
    render: h => (
      <g transform="translate(448 408)">
        <g transform="translate(-2.853 0.25)">
          <path d="M5.216,11.155a.778.778,0,1,1,0-1.556H8.467l-5.9-5.9A.8.8,0,0,1,3.7,2.566L9.458,8.327V5.356a.784.784,0,0,1,.778-.778.865.865,0,0,1,.636.212.765.765,0,0,1,.212.5v5.091a.785.785,0,0,1-.778.778Z" transform="translate(5242.527 -32.079) rotate(-90)"/>
          <rect width="12" height="1.5" rx="0.75" transform="translate(5255.25 -31.25) rotate(-90)"/>
        </g>
      </g>
    ),
  },
  sound_on: {
    viewBox: '5638 361 20 20',
    width: 20,
    height: 20,
    render: h => (
      <g transform="translate(5616.8 361)">
        <path d="M30.2,16.8a5.375,5.375,0,0,1-4.4-2.3H24.2a.789.789,0,0,1-.8-.8V6.3a.789.789,0,0,1,.8-.8h1.6a5.132,5.132,0,0,1,4.4-2.3A.789.789,0,0,1,31,4V16C30.9,16.4,30.6,16.8,30.2,16.8Zm-5.3-3.9h1.2a1.012,1.012,0,0,1,.7.4,3.812,3.812,0,0,0,2.6,1.9V4.8a3.543,3.543,0,0,0-2.6,1.9.842.842,0,0,1-.7.4H24.9Z"/>
        <path d="M34.2,14.3a.367.367,0,0,1-.3-.1.693.693,0,0,1-.3-1,7.774,7.774,0,0,0,.8-3V10a8.036,8.036,0,0,0-.8-3.1.738.738,0,1,1,1.3-.7,9.071,9.071,0,0,1,1,3.8v.2a9.13,9.13,0,0,1-1,3.7A.67.67,0,0,1,34.2,14.3Z"/>
        <path d="M36.7,16.3a.6.6,0,0,1-.4-.1.775.775,0,0,1-.2-1,10.4,10.4,0,0,0,1.3-4.9V10a10.165,10.165,0,0,0-1.3-4.9.775.775,0,0,1,.2-1,.775.775,0,0,1,1,.2,11.342,11.342,0,0,1,1.6,5.8v.2a11.774,11.774,0,0,1-1.6,5.8A.845.845,0,0,1,36.7,16.3Z"/>
      </g>
    ),
  },
  sound_off: {
    viewBox: '5638 393 20 20',
    width: 20,
    height: 20,
    render: h => (
      <g transform="translate(5618.1 393)">
        <path d="M28.9,16.8a5.375,5.375,0,0,1-4.4-2.3H22.9a.789.789,0,0,1-.8-.8V6.3a.789.789,0,0,1,.8-.8h1.6a5.132,5.132,0,0,1,4.4-2.3.789.789,0,0,1,.8.8V16A.86.86,0,0,1,28.9,16.8Zm-5.2-3.9h1.2a1.012,1.012,0,0,1,.7.4,3.812,3.812,0,0,0,2.6,1.9V4.8a3.543,3.543,0,0,0-2.6,1.9.842.842,0,0,1-.7.4H23.7Z"/>
        <path fill="#fa6962" d="M37.4,13.3a.764.764,0,0,1-.5-.2l-5-5A.778.778,0,1,1,33,7l5,5a.785.785,0,0,1,0,1.1A1.421,1.421,0,0,1,37.4,13.3Z"/>
        <path fill="#fa6962" d="M32.4,13.3a.764.764,0,0,1-.5-.2.785.785,0,0,1,0-1.1l5-5A.778.778,0,0,1,38,8.1l-5,5A1.421,1.421,0,0,1,32.4,13.3Z"/>
      </g>
    ),
  },
  micro_on: {
    viewBox: '5582 393 20 20',
    width: 20,
    height: 20,
    render: h => (
      <g transform="translate(5553.1 391)">
        <path d="M-5313.8,47.7V45.057c-3.953-.435-5.106-4.084-5.2-6.056a.772.772,0,0,1,.7-.8.772.772,0,0,1,.8.7c0,.2.2,4.7,4.5,4.7s4.5-4.5,4.5-4.7a.666.666,0,0,1,.8-.7.772.772,0,0,1,.7.8c0,1.973-1.236,5.622-5.2,6.056V47.7a.86.86,0,0,1-.8.8A.788.788,0,0,1-5313.8,47.7Zm-2.5-8.5V35.3A3.33,3.33,0,0,1-5313,32a3.352,3.352,0,0,1,3.4,3.3v3.9a3.33,3.33,0,0,1-3.3,3.3A3.352,3.352,0,0,1-5316.3,39.2Zm1.5-3.9v3.9A1.792,1.792,0,0,0-5313,41h.1a1.792,1.792,0,0,0,1.8-1.8V35.3a1.792,1.792,0,0,0-1.8-1.8A1.816,1.816,0,0,0-5314.8,35.3Z" transform="translate(5351.9 -28.2)"/>
      </g>
    ),
  },
  micro_off: {
    viewBox: '5610 393 20 20',
    width: 20,
    height: 20,
    render: h => (
      <g transform="translate(5610 391)">
        <path d="M8.1,9.7V7.1A1.817,1.817,0,0,1,10,5.3a1.793,1.793,0,0,1,1.8,1.8V11a2.036,2.036,0,0,1-.9,1.6L12,13.7a3.317,3.317,0,0,0,1.3-2.6v-4a3.35,3.35,0,0,0-6.7,0V8.2Z"/>
        <path d="M12.9,14.4,14,15.5a7.308,7.308,0,0,0,2.1-4.7.752.752,0,0,0-1.5-.1C14.5,10.9,14.4,13.1,12.9,14.4Z"/>
        <path fill="#fa6962" d="M16,17.3a.764.764,0,0,1-.5-.2L3.5,5a.668.668,0,0,1,0-1A.785.785,0,0,1,4.6,4l12,12a.785.785,0,0,1,0,1.1A1.421,1.421,0,0,1,16,17.3Z"/>
        <path d="M10,14.3h0a4.3,4.3,0,0,0,1.2-.2L9.9,12.8a1.915,1.915,0,0,1-1.7-1.7L6.6,9.6V11A3.351,3.351,0,0,0,10,14.3Z"/>
        <path d="M13.1,16.1,12,15a3.966,3.966,0,0,1-2,.4c-4.3,0-4.5-4.5-4.5-4.7a.752.752,0,0,0-1.5.1c0,2,1.2,5.7,5.2,6.1v2.6a.789.789,0,0,0,.8.8.736.736,0,0,0,.8-.8V16.9A5.231,5.231,0,0,0,13.1,16.1Z"/>
      </g>
    ),
  },
  micro_off_solid: {
    viewBox: '5610 393 20 20',
    width: 20,
    height: 20,
    render: h => (
      <g transform="translate(5610 391)">
        <path d="M8.1,9.7V7.1A1.817,1.817,0,0,1,10,5.3a1.793,1.793,0,0,1,1.8,1.8V11a2.036,2.036,0,0,1-.9,1.6L12,13.7a3.317,3.317,0,0,0,1.3-2.6v-4a3.35,3.35,0,0,0-6.7,0V8.2Z"/>
        <path d="M12.9,14.4,14,15.5a7.308,7.308,0,0,0,2.1-4.7.752.752,0,0,0-1.5-.1C14.5,10.9,14.4,13.1,12.9,14.4Z"/>
        <path d="M16,17.3a.764.764,0,0,1-.5-.2L3.5,5a.668.668,0,0,1,0-1A.785.785,0,0,1,4.6,4l12,12a.785.785,0,0,1,0,1.1A1.421,1.421,0,0,1,16,17.3Z"/>
        <path d="M10,14.3h0a4.3,4.3,0,0,0,1.2-.2L9.9,12.8a1.915,1.915,0,0,1-1.7-1.7L6.6,9.6V11A3.351,3.351,0,0,0,10,14.3Z"/>
        <path d="M13.1,16.1,12,15a3.966,3.966,0,0,1-2,.4c-4.3,0-4.5-4.5-4.5-4.7a.752.752,0,0,0-1.5.1c0,2,1.2,5.7,5.2,6.1v2.6a.789.789,0,0,0,.8.8.736.736,0,0,0,.8-.8V16.9A5.231,5.231,0,0,0,13.1,16.1Z"/>
      </g>
    ),
  },
}
