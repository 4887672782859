/* eslint-disable @typescript-eslint/no-unused-vars */
export default {
  add_contact: {
    viewBox: '5912 1159 24 24',
    render: h => (
      <g transform="translate(4951 -2961)">
        <path d="M.612,19.54a.768.768,0,0,1-.592-.888,9.32,9.32,0,0,1,18.356,0,.769.769,0,0,1-.592.888h-.2c-.264,0-7.545.044-12.486.044C2.628,19.584.743,19.573.612,19.54ZM18.77,12.533V9.573H15.81a.7.7,0,0,1-.691-.79.7.7,0,0,1,.691-.79h2.96V5.033a.8.8,0,0,1,1.579,0v2.96h2.96a.7.7,0,0,1,.691.79.7.7,0,0,1-.691.79h-2.96v2.96a.837.837,0,0,1-.79.691A.763.763,0,0,1,18.77,12.533ZM4.658,4.737A4.737,4.737,0,1,1,9.4,9.474,4.738,4.738,0,0,1,4.658,4.737Z" transform="translate(961 4122)"/>
      </g>
    ),
  },
  arr_open: {
    viewBox: '0 0 24 24',
    render: h => (
      <g transform="translate(945 3225) rotate(-90)">
        <path d="M5.852,7.124.263,1.536,1.537.264,6.488,5.216,11.44.264l1.272,1.272L7.124,7.124a.9.9,0,0,1-1.272,0ZM11.44.264a.9.9,0,1,1,1.272,1.272ZM.263.264a.9.9,0,0,1,1.273,0L.263,1.536A.9.9,0,0,1,.263.264Z" transform="translate(3207 -936.6)"/>
      </g>
    ),
  },
  add_section: {
    viewBox: '5912 1127 24 24',
    render: h => (
      <g transform="translate(3931 -3234)">
        <path d="M-3927,3262a2,2,0,0,1-2-2h16a2,2,0,0,1-2,2Zm-1-3a2,2,0,0,1-2-2v-.267a2,2,0,0,0,1,.267h16a2,2,0,0,0,1-.267V3257a2,2,0,0,1-2,2Zm-1-3a2,2,0,0,1-2-2v-8a2,2,0,0,1,2-2h16a2,2,0,0,1,2,2v8a2,2,0,0,1-2,2Zm3.32-6.1a.714.714,0,0,0,.7.8h3v3a.778.778,0,0,0,.8.7.846.846,0,0,0,.8-.7v-3h3a.714.714,0,0,0,.7-.8.714.714,0,0,0-.7-.8h-3v-3a.715.715,0,0,0-.8-.7.715.715,0,0,0-.8.7v3h-3A.714.714,0,0,0-3925.681,3249.9Z" transform="translate(5914 1120)"/>
      </g>
    ),
  },
  // 'download': {
  //   viewBox: '7634 2047 24 24',
  //   render: (h) => (
  //     <g transform="translate(7636 2068) rotate(-90)">
  //       <path d="M-7399.439-2114.275l-6.6-6.6a.781.781,0,0,1,0-1.1l6.6-6.6a1.41,1.41,0,0,1,.6-.2.369.369,0,0,1,.3.1.754.754,0,0,1,.5.7v3.1h8.2a.714.714,0,0,1,.7.8v5.5a.772.772,0,0,1-.7.8h-8.3v3a.753.753,0,0,1-.5.7,1.028,1.028,0,0,1-.324.06A.539.539,0,0,1-7399.439-2114.275Zm-5-7.1,4.8,4.8v-2a.86.86,0,0,1,.8-.8h8.3v-4h-8.3a.788.788,0,0,1-.8-.8v-2Zm-5.743,6.168v-12.5a.751.751,0,0,1,.751-.749.75.75,0,0,1,.749.749v12.5a.749.749,0,0,1-.749.75A.75.75,0,0,1-7410.183-2115.207Z" transform="translate(7408.683 2131.458)"/>
  //     </g>
  //   )
  // },
  close: {
    viewBox: '7688 2047 24 24',
    render: h => (
      <g transform="translate(4491 2856)">
        <path d="M12.534,13.594,4.786,5.845l-7.749,7.748a.749.749,0,0,1-1.06,0,.75.75,0,0,1,0-1.06L3.725,4.785-4.022-2.962l1.061-1.061L4.786,3.724l7.747-7.747,1.06,1.061L5.847,4.785,13.6,12.533a.75.75,0,1,1-1.061,1.06Zm0-17.617a.75.75,0,0,1,1.06,0,.75.75,0,0,1,0,1.061Zm-16.556,0a.751.751,0,0,1,1.061,0L-4.022-2.962A.751.751,0,0,1-4.022-4.023Z" transform="translate(3204.414 -801.586)"/>
      </g>
    ),
  },
  info: {
    viewBox: '5265 68 24 24',
    render: h => (
      <g transform="translate(-54 -150)">
        <g transform="translate(1888.5 1084.5)">
          <g transform="translate(3432.5 -864.5)">
            <path d="M 10.00000095367432 18.5 C 14.68692111968994 18.5 18.5 14.68692111968994 18.5 10.00000095367432 C 18.5 5.313080787658691 14.68692111968994 1.500000953674316 10.00000095367432 1.500000953674316 C 5.313080787658691 1.500000953674316 1.500000953674316 5.313080787658691 1.500000953674316 10.00000095367432 C 1.500000953674316 14.68692111968994 5.313080787658691 18.5 10.00000095367432 18.5 M 10.00000095367432 20 C 4.477150917053223 20 9.5367431640625e-07 15.52285099029541 9.5367431640625e-07 10.00000095367432 C 9.5367431640625e-07 4.477150917053223 4.477150917053223 9.5367431640625e-07 10.00000095367432 9.5367431640625e-07 C 15.52285099029541 9.5367431640625e-07 20 4.477150917053223 20 10.00000095367432 C 20 15.52285099029541 15.52285099029541 20 10.00000095367432 20 Z"/>
          </g>
          <path d="M-1834.327-924.75v-4.573a1.142,1.142,0,0,1,1.121-1.121,1.142,1.142,0,0,1,1.121,1.121v4.573a1.142,1.142,0,0,1-1.121,1.121A1.142,1.142,0,0,1-1834.327-924.75Zm-.173-8.456a1.327,1.327,0,0,1,1.294-1.294,1.327,1.327,0,0,1,1.294,1.294,1.327,1.327,0,0,1-1.294,1.294A1.27,1.27,0,0,1-1834.5-933.206Z" transform="translate(5275.718 74.103)"/>
        </g>
      </g>
    ),
  },
  search: {
    viewBox: '4969 68 24 24',
    render: h => (
      <g transform="translate(2072 834)">
        <path d="M15.819,16.88l-3.573-3.573A7.5,7.5,0,0,1,0,7.5H1.5a6,6,0,1,0,6-6,6,6,0,0,0-6,6H0a7.5,7.5,0,1,1,13.307,4.747l3.573,3.572a.75.75,0,0,1-1.061,1.061Z" transform="translate(2901 -763)"/>
      </g>
    ),
  },
  briefcase: {
    viewBox: '0 0 24 24',
    render: h => (
      <path d="M10 2.25C9.27065 2.25 8.57118 2.53973 8.05546 3.05546C7.53973 3.57118 7.25 4.27065 7.25 5V6.25H4C2.48122 6.25 1.25 7.48122 1.25 9V19C1.25 20.5188 2.48122 21.75 4 21.75H20C21.5188 21.75 22.75 20.5188 22.75 19V9C22.75 7.48122 21.5188 6.25 20 6.25H16.75V5C16.75 4.27065 16.4603 3.57118 15.9445 3.05546C15.4288 2.53973 14.7293 2.25 14 2.25H10ZM15.25 6.25H8.75V5C8.75 4.66848 8.8817 4.35054 9.11612 4.11612C9.35054 3.8817 9.66848 3.75 10 3.75H14C14.3315 3.75 14.6495 3.8817 14.8839 4.11612C15.1183 4.35054 15.25 4.66848 15.25 5V6.25ZM8.75 7.75H15.25V20.25H8.75V7.75ZM7.25 7.75V20.25H4C3.30964 20.25 2.75 19.6904 2.75 19V9C2.75 8.30964 3.30964 7.75 4 7.75H7.25ZM16.75 20.25V7.75H20C20.6904 7.75 21.25 8.30964 21.25 9V19C21.25 19.6904 20.6904 20.25 20 20.25H16.75Z"/>
    ),
  },
  users: {
    viewBox: '0 0 640 512',
    render: h => (
      <path d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"/>
    ),
  },
}
