/* eslint-disable @typescript-eslint/no-unused-vars */
export default {
  upload: {
    viewBox: '4963 320 48 48',
    render: h => (
      <g transform="translate(2427 -1660)">
        <path d="M20,30.607V14.744l-5.924,5.924a1.5,1.5,0,0,1-2.122-2.121l8.485-8.485a1.5,1.5,0,0,1,2.122,0L21.5,11.122h0l1.061-1.06,8.485,8.485a1.5,1.5,0,0,1-2.122,2.121L23,14.744V30.607a1.5,1.5,0,0,1-3,0Zm2.864-16h0ZM33.5,24a6.5,6.5,0,0,0,0-13v0A1.5,1.5,0,0,1,32,9.5a4.5,4.5,0,0,0-7.434-3.411,1.5,1.5,0,0,1-2.16-.212A7.5,7.5,0,0,0,9,10.5H6A10.5,10.5,0,0,1,23.814,2.968,7.5,7.5,0,0,1,34.869,8.1,9.5,9.5,0,0,1,33.5,27ZM22.406,5.877l1.18-.925h0ZM27.488,27V24H33.5v3Zm-1.5-1.5a1.5,1.5,0,0,1,1.5-1.5v3A1.5,1.5,0,0,1,25.987,25.5ZM7.5,27V24h6.988a1.5,1.5,0,1,1,0,3ZM0,19.5H3A4.5,4.5,0,0,0,7.5,24v3A7.5,7.5,0,0,1,0,19.5Zm0,0a7.5,7.5,0,0,1,6.125-7.375A10.569,10.569,0,0,1,6,10.5H9A7.5,7.5,0,0,0,9.427,13a1.5,1.5,0,0,1-1.415,2H7.5A4.5,4.5,0,0,0,3,19.5Z" transform="translate(2538 1988)"/>
      </g>
    ),
    active: {
      viewBox: '5031 320 48 48',
      render: h => (
        <g transform="translate(5031 320)">
          <path d="M36.9,16.1a8.955,8.955,0,0,0-.8-2.3A7.409,7.409,0,0,0,25.9,11a10.64,10.64,0,0,0-7.3-3A10.521,10.521,0,0,0,8,18.5a8.6,8.6,0,0,0,.1,1.6A7.517,7.517,0,0,0,9.5,35H22V22.7l-5.9,5.9A1.485,1.485,0,0,1,14,26.5L22.5,18a1.45,1.45,0,0,1,2.1,0h0l8.5,8.5A1.485,1.485,0,0,1,31,28.6l-6-5.9V35H35.5a9.5,9.5,0,0,0,1.4-18.9Z"/>
          <path d="M22,35v3.6a1.5,1.5,0,0,0,3,0V35Z"/>
        </g>
      ),
    },
  },
}
